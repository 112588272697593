<template>
  <b-modal :id="modalId" title="Yêu cầu xuất dữ liệu">
    <br />
    <div class="d-block text-center">
      <b-form-textarea placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..." v-model="description" rows="3"></b-form-textarea>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="danger" @click="cancel()"> Hủy </b-button>
      <b-button size="sm" variant="success" @click="exportData()">
        Xuất
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ExportModal",
  props: ["modalId"],
  data: () => ({
    description: null,
  }),

  methods: {
    exportData() {
      this.$emit("exportData", this.description);
    },

    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
